
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import store from "@/store";
import RegisterTime from "./RegisterTime.vue";
import RegisterTip from "./RegisterTip.vue";
import DoctorOrder from "./DoctorOrder.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigSection",
  components: { RegisterTime, RegisterTip, DoctorOrder },
  setup() {
    let data = reactive<any>({
      //crud: ref(null),
      init: false,
      data: {},
      option: {},
      defaults: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 400],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-OrderNum",
      },
      timeDialogVisible: false,
      tipDialogVisible: false,
      doctorDialogVisible: false,
      timeStatus: [],
    });

    const searchChange = (params: any, done: any) => {
      console.log(params);
      if (!params["HospitalID"]) {
        ElMessage.warning("请选择医院");
        done();
        return;
      }
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      if (!data.query.where["HospitalID"]) {
        return;
      }
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      let Docking = false;
      const allHospital: string = store.getters.allHospital;
      if (allHospital) {
        const jsonHospital = JSON.parse(allHospital);
        jsonHospital.forEach((p: any) => {
          p.children.forEach((c: any) => {
            c.children.forEach((h: any) => {
              if (h.value == data.query.where.HospitalID) Docking = h.Docking;
            });
          });
        });
      }

      Api.Config.GetSectionList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          res.Data.data.forEach((element: any) => {
            element.ssid = element.ssid + "";
          });

          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;

          if (Docking) {
            data.defaults.zdks.hide = true;
            data.defaults.szd.hide = true;
            data.defaults.gzd.hide = true;
            data.option.editBtn = false;
          } else {
            data.defaults.zdks.hide = false;
            data.defaults.szd.hide = false;
            data.defaults.gzd.hide = false;
            data.option.editBtn = true;
            getTimeStatusList();
          }

          //data.crud.refreshTable();
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getTimeStatusList = () => {
      var ids: number[] = [];
      data.data.data.forEach((element: any) => {
        ids.push(element.SectionID);
      });
      if (ids.length == 0) return;
      var action = {
        timetype: 2,
        ids: ids,
      };
      Api.Config.GetTimeStatusList(action).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          data.timeStatus = res.Data;
        } else {
          data.data = [];
        }
      });
    };

    const haveTime = computed(() => (id: number) => {
      var status = data.timeStatus.find((item: any) => item.id == id);
      if (status) {
        return data.timeStatus.find((item: any) => item.id == id).have
          ? "primary"
          : "warning";
      } else {
        return "warning";
      }
    });

    const dockingHospital = computed(() => (id: number) => {
      let Docking = false;
      const allHospital: string = store.getters.allHospital;
      if (allHospital) {
        const jsonHospital = JSON.parse(allHospital);
        jsonHospital.forEach((p: any) => {
          p.children.forEach((c: any) => {
            c.children.forEach((h: any) => {
              if (h.value == id) Docking = h.Docking;
            });
          });
        });
      }
      return Docking;
    });

    const getOption = () => {
      Api.Config.GetSectionListOption().then((res: any) => {
        console.log(res);
        const allHospital: string = store.getters.allHospital;
        if (allHospital) {
          const jsonHospital = JSON.parse(allHospital);
          console.log(jsonHospital);

          jsonHospital.forEach((p: any) => {
            p.children.forEach((c: any) => {
              c.children.forEach((h: any) => {
                if (h.Docking) h.label = h.label + " - 直连";
                h.value = h.value + "";
              });
            });
          });
          res.column.filter((c: any) => {
            if (c.prop == "HospitalID") c.dicData = jsonHospital;
          });
        }
        data.option = res;
        data.init = true;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      Api.Config.AddSection(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      Api.Config.EditSection(form.SectionID, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const SectionRemove = (row: any) => {
      Api.Config.EditSection(row.SectionID, row).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    const sortableChange = (oldindex: number, newindex: number) => {
      if (!data.query.where["HospitalID"] || data.query.where["SectionName"]) {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "请选择一家医院，清空科室名称查询条件，查询后进行排序",
        });
        getList();
        return;
      }
      if (oldindex == newindex) return;
      const order = {
        HospitalID: data.query.where["HospitalID"],
        key: data.data.data[newindex].SectionID,
        steps: oldindex - newindex,
      };
      Api.Config.OrderSection(order).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });

      // Api.Config.OrderSection(order).then((res: any) => {
      //   if (res.Code == 0) {
      //     ElMessage.success("保存成功");
      //   } else {
      //     ElMessage.warning({
      //       showClose: true,
      //       duration: 10000,
      //       message: res.ResultMsg,
      //     });
      //     getList();
      //   }
      // });
    };

    const RegisterTimeRef = ref<InstanceType<typeof RegisterTime>>();
    const SetTime = (row: any) => {
      data.timeDialogVisible = true;
      RegisterTimeRef.value?.searchChange(2, row.SectionID, row.SectionName);
    };

    const RegisterTipRef = ref<InstanceType<typeof RegisterTip>>();
    const SetTip = (row: any) => {
      data.tipDialogVisible = true;
      RegisterTipRef.value?.searchChange(
        4,
        row.SectionID,
        row.HospitalID,
        row.SectionName
      );
    };

    const DoctorOrderRef = ref<InstanceType<typeof DoctorOrder>>();
    const SetDoctorOrder = (row: any) => {
      data.doctorDialogVisible = true;
      DoctorOrderRef.value?.searchChange(row.HospitalID, row.SectionID);
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowUpdate,
      SectionRemove,
      SetTime,
      sortableChange,
      RegisterTimeRef,
      RegisterTipRef,
      SetTip,
      haveTime,
      dockingHospital,
      SetDoctorOrder,
      DoctorOrderRef,
    };
  },
});
