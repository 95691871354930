
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "ConfigDoctorOrder",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 999,
        pageSizes: [999],
        layout: "total",
      },
      query: {
        limit: 999,
        page: 1,
        where: {},
        sort: "-OrderNum",
      },
      timeDialogVisible: false,
      timeStatus: [],
      HosId: 0,
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const searchChange = (HospitalID: number, DeptId: number) => {
      data.HosId = HospitalID;
      data.query.where = {
        HospitalID: HospitalID,
        DeptId: DeptId,
      };
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      console.log(data.query);

      getList();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetDockingDoctorList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.data.data.forEach((element: any) => {
            element.RegisterStatus == 0
              ? (element.RegisterStatus = true)
              : (element.RegisterStatus = false);
          });
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const DoctorRemove = (row: any) => {
      var aa = {
        DocId: row.DocId,
        HosId: data.HosId,
        RegisterStatus: row.RegisterStatus,
      };
      Api.Config.EditDockingDoctor(row.DocId, aa).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    const sortableChange = (oldindex: number, newindex: number) => {
      if (oldindex == newindex) return;
      const order = {
        HospitalID: data.HosId,
        key: data.data.data[newindex].DocId,
        steps: oldindex - newindex,
      };
      Api.Config.OrderDockingDoctor(order).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    const getOption = () => {
      Api.Config.GetDockingDoctorListOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      ...toRefs(props),
      dialogVisible,
      searchChange,
      changePage,
      sortableChange,
      DoctorRemove,
    };
  },
});
