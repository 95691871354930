import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_avue_crud = _resolveComponent("avue-crud")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "医生排序",
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      (_ctx.init)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_avue_crud, {
              data: _ctx.data.data,
              option: _ctx.option,
              page: _ctx.page,
              "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
              onSortableChange: _ctx.sortableChange,
              onSearchChange: _ctx.searchChange,
              onOnLoad: _ctx.changePage
            }, {
              RegisterStatus: _withCtx((scope) => [
                _createVNode(_component_el_switch, {
                  modelValue: scope.row.RegisterStatus,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.RegisterStatus) = $event),
                  onChange: ($event: any) => (_ctx.DoctorRemove(scope.row))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["data", "option", "page", "onSortableChange", "onSearchChange", "onOnLoad"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}